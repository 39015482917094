<template>
  <div>
    <TopNav v-bind:showLogin=false v-bind:showStores=true />
    <SidebarNav/>
    <div v-if="!profile.user || updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div class="row">
        <div class="col-sm-12 mt-md-5">
          <h1 class="mt-4">Profile</h1>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-3">
          <SamTextField
            label="Email (username)"
            v-model="profile.user.email"
            :disabled="true"
          />
        </div>
        <div class="col-sm-3">
          <SamTextField
            label="First name*"
            v-model="profile.user.first_name"
            :error="!$v.profile.user.first_name.required"
            errorMessage="First name is required"
          />
        </div>
        <div class="col-sm-3">
          <SamTextField
            label="Last name*"
            v-model="profile.user.last_name"
            :error="!$v.profile.user.last_name.required"
            errorMessage="Last name is required"
          />
        </div>
        <div class="col-sm-3">
          <SamTextField
            label="Phone number*"
            v-model="profile.phone"
            :error="$v.profile.phone.$error"
            :errorMessage="!$v.profile.phone.required ? 'Phone number is required' : 'Phone number format seems invalid'"
            pattern="[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
         <SamLabel label="Preferred ref. or cost center"/>
          <v-select
            label="name"
            class="sam-input sam-select"
            :options="costcenters"
            :reduce="cc => cc.id"
            :clearable="false"
            v-model="membership.default_cc"
          ></v-select>
        </div>
        <div class="col-sm-3 mt-4 mt-md-0">
          <SamLabel label="Preferred delivery location"/>
          <v-select
            label="name"
            class="sam-input sam-select"
            :options="addresses"
            :reduce="a => a.id"
            :clearable="false"
            v-model="membership.default_address"
          ></v-select>
        </div>
        <div class="col-sm-3">
        </div>
        <div class="col-sm-3 mt-4 mt-md-0 d-flex align-items-end">
          <div>&nbsp;</div>
          <SamPrimaryButton
            text="Save"
            @buttonAction="save()"
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import TopNav from '@/modules/general/components/TopNav.vue'
import Footer from '@/modules/general/components/Footer.vue'
import { required } from 'vuelidate/lib/validators'
import SidebarNav from "@/modules/general/components/sidebar/SidebarNav.vue";
import SamTextField from '../../../components/SamTextField.vue';
import SamLabel from '../../../components/SamLabel.vue';
import SamPrimaryButton from '../../../components/SamPrimaryButton.vue';

export default {
  name: 'AccountSettings',
  components: {
    SidebarNav,
    TopNav,
    Footer,
    SamTextField,
    SamLabel,
    SamPrimaryButton
  },
  data() {
    return {
      selectedaddr: null,
      selectedcc: null,
      updating: false,
      profile: {},
      membership: {}
    }
  },
  validations: {
    profile: {
      user: {
        first_name: {
          required
        },
        last_name: {
          required
        }
      },
      phone: {
        required,
        phoneFormat(phone){
          if(phone == "" || phone == null){
            return true;
          }
          return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(phone)
        }
      }
    }
  },
  mounted: function(){
    if(this.profileData.id){
      this.profile = {...this.profileData};
    }
    if(this.membershipData.id){
      this.membership = {...this.membershipData};
    }
  },
  computed: {
    profileData(){
      return this.$store.getters.profile
    },
    costcenters () {
      if(this.$store.getters.costcenters.length < 1 && !this.$store.getters.ccsFetched){
        this.$store.dispatch('fetchCostCenters');
        return []
      }
      if(!this.membership.costcenters){
        return []
      }
      return this.$store.getters.costcenters.filter(cc => this.membership.costcenters.includes(cc.id));
    },
    addresses () {
      if(this.$store.getters.addresses.length < 1 && !this.$store.getters.addressesFetched){
        this.$store.dispatch('fetchAddresses');
      }
      return this.$store.getters.addresses;
    },
    membershipData() {
      return this.$store.getters.membership;
    },
    ccsFetched(){
      return this.$store.getters.ccsFetched;
    }
  },
  watch: {
    ccsFetched(newVal){
      if(newVal){
        if(this.membership.id){
          if(!this.costcenters.find(cc => cc.id == this.membership.default_cc)){
            this.membership.default_cc = null;
          }
        }
      }
    },
    profileData(newVal, oldVal){
      if(newVal.id && !oldVal.id){
        this.profile = {...newVal};
      }
    },
    membershipData(newVal, oldVal){
      if(newVal.id && !oldVal.id){
        this.membership = {...newVal}
        if(this.ccsFetched){
          if(!this.costcenters.find(cc => cc.id == this.membership.default_cc)){
            // set default cc to null if not in list of allowed ccs
            this.membership.default_cc = null;
          }
        }
      }
    }
  },
  methods: {

    save() {
      this.updating = true;
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.updating = false;
        return;
      }
      const data = this.profile;
      const vm = this;
      this.$http({
        url: process.env.VUE_APP_API_URL + "/api/users/details/",
        method: 'PUT',
        data: data
      })
      .then(response => {
        // update profile to vuex store
        vm.$store.commit('updateProfile', response.data);
        const data = vm.membership;
        vm.$http({
          url: process.env.VUE_APP_API_URL + "/api/stores/" + vm.$store.getters.activeStore.id + "/members/" + vm.membership.id + "/",
          method: 'PUT',
          data: data
        })
        .then(response => {
          // update profile to vuex store
          vm.$store.commit('updateMember', response.data);
          vm.$toastr.s(
            "Your changes have been saved."
          );
          vm.updating = false;
        })
        .catch(err => {
          vm.updating = false;
          if (err.response && err.response.data){
            vm.$toastr.e(
              err.response.data
            );
          }
        })
      })
      .catch(err => {
        vm.updating = false;
        if (err.response && err.response.data){
          vm.$toastr.e(
            err.response.data
          );
        }
      })
    }
  }
}
</script>
